import React from 'react';
import moment from 'moment';
import { Grid } from '@mui/material';
import { Validator } from 'redux-form';
import { fetchGpis } from 'services/utils/therapy-service';
import { stripFormattedDollarValue } from 'services/utils/formatting';
import { IContactList } from 'interfaces/redux/IContactList';
import { IFillCoordinationTask } from 'interfaces/redux/task-types/IFillCoordinationTask';
import { IFillCycle, IFillCycles } from 'interfaces/redux/IFillCycles';
import { IPatient } from 'interfaces/redux/IPatient';
import { ITherapy } from 'interfaces/redux/ITherapies';
import {
  methodOfDelivery,
  deliveryShippingMethodMap,
  defaultPreferencesAncillarySupplies,
} from 'constants/lists';
import {
  DeliveryMethodValue,
  OrderMethod,
  DeliveryMethodLabel,
  FulfillmentStatus,
} from 'constants/enums';
import { ReduxFormUtil } from 'utils/redux-form-util';
import { TaskId, TherapyId } from 'interfaces/RecordTypes';
import { ICompleteFcGeneratorTask } from 'components/document/generator/interfaces/fc-types';
import { WeatherService } from 'services/utils/weather-service';
import { nameOfFactory } from 'utils/types-util';
import {
  CompleteTaskPayload,
  IAdditionalMedication,
  IBundleTask,
  IBundleTaskOld,
  ICompleteFillCoordinationBundleTaskPayload,
  IDocument,
  INote,
  IOrder,
  IFulfillment,
} from 'interfaces/fill-coordination/bundle-types';
import { IWeatherView } from 'interfaces/weather/IWeatherView';
import { NoteTagTypes } from 'constants/note-tag-types';
import { NoteTypes } from 'constants/note-types';
import { FillCoordinationStatus } from 'interfaces/enums/TaskStatuses/FillCoordinationStatus';
import { InterventionCategoryId } from 'interfaces/enums/InterventionCategory';
import { InterventionTypeId } from 'interfaces/enums/InterventionType';
import { TaskCategory } from 'interfaces/enums/TaskCategory';
import { FillCoordinationStatusMap } from 'constants/task-statuses';
import { IPaymentMethodType } from 'interfaces/redux/IPaymentMethodType';
import { ILabelValueNumber } from 'interfaces/ILabelValue';
import { IUpsPackagingTypes } from 'interfaces/redux/ILookups';
import { isEmpty } from 'lodash';
import { IAllAddress } from 'interfaces/redux/IAllAddress';
import { Timezone } from 'models/time/timezone';
import { ITask } from 'interfaces/redux/ITasks';
import { IState } from 'interfaces/redux/IState';
import { createSelector } from '@reduxjs/toolkit';
import { IPharmacy } from 'models/services/providers/pharmacy/IPharmacy';
import IconWithTooltip from '../../../indicators/indicators';
import UspsLogo from '../../../lib/shipping-logos/usps.png';
import UpsLogo from '../../../lib/shipping-logos/ups.png';
import FedexLogo from '../../../lib/shipping-logos/fedex.png';
import { logger } from '../../../winston-logger';
import {
  getFullAddressList,
  getFullEmailList,
  getFullPhoneList,
  getPreferredAddressForFC,
  getPreferredEmailAddress,
  getPreferredPhoneNumber,
} from '../../../services/utils/demographic-service';
import {
  FILL_COORDINATION_STATUS_REQUIRED,
  FILL_COORDINATION_STATUS_COMPLETED,
  FILL_COORDINATION_STATUS_CANCELED,
  FC,
  DATABASE_DATETIME_FORMAT,
  DATABASE_DATE_FORMAT,
  UPLOAD_DOC_FILENAME_FIELD,
  UPLOAD_DOC_FILETYPE_FIELD,
  UPLOAD_DOC_FILENOTE_FIELD,
  FILL_COORDINATION_STATUS,
} from '../../../constants/index';
import {
  EditComponentMode,
  IFillCoordinationEditFormFields,
  IShortIntervention,
  IInterventionPayload,
  SideEffectType,
} from './types';
import { defined, definedBoolean } from '../../../components/form/validation/validation';
import { convertToArborDate } from '../../../models/time/arbor-date';
import { buildObjectForJson } from '../../../helpers/misc';
import { PaymentMethodResponse } from '../../add-payment-method/types';
import { MAX_SELECTED_PAYMENT_METHODS } from './payment-methods/payment-method-constants';

type TherapyFillCycle = Pick<ITherapy & IFillCycle, 'needsby_date' | 'days_supply'>;

const nameOfFormFields = nameOfFactory<IFillCoordinationEditFormFields>();

const REQUIRED_INT_STATUS_ID = 8001;
const OLD_DELIVERY_ID = 2;
const controlledSubstanceList = [2, 3, 4, 5];

const canceledStatuses: number[] = Object.entries(FillCoordinationStatusMap)
  .filter(([, value]) => value.category === TaskCategory.Archived)
  .map(([key]) => Number(key));

export const otcProvider = (inputValue: any) =>
  new Promise((resolve, reject) => {
    fetchGpis(inputValue)
      .then((result: any) =>
        resolve(
          result.data.drugs.map((item: any) => ({
            value: item,
            label: `${item.drug_info} - NDC: ${item.ndc}`,
          })),
        ),
      )
      .catch((err: any) => reject(err));
  });

export const isDeliveryMethodSelected = (deliveryMethod?: DeliveryMethodValue): boolean => {
  if (
    deliveryMethod &&
    [DeliveryMethodValue.FedEx, DeliveryMethodValue.Ups, DeliveryMethodValue.Usps].includes(
      deliveryMethod,
    )
  ) {
    return true;
  }
  return false;
};

export const isCourierMethodSelected = (deliveryMethod?: DeliveryMethodValue): boolean => {
  if (deliveryMethod && [DeliveryMethodValue.Courier].includes(deliveryMethod)) {
    return true;
  }
  return false;
};

export const getDeliveryMethodObject = (
  deliveryMethodId?: number,
  shippingMethod?: string,
): { label: DeliveryMethodLabel; value: DeliveryMethodValue } | undefined => {
  let deliveryMethodObject: { label: DeliveryMethodLabel; value: DeliveryMethodValue } | undefined;
  if (deliveryMethodId === OLD_DELIVERY_ID && shippingMethod) {
    try {
      const [shippingMethodKey] = Object.keys(JSON.parse(shippingMethod));
      if (shippingMethodKey) {
        const methodKey = Object.keys(deliveryShippingMethodMap).find(deliveryMethodKey =>
          deliveryShippingMethodMap[Number(deliveryMethodKey)].some(
            dMethod => dMethod.value === shippingMethodKey,
          ),
        );

        deliveryMethodObject = methodOfDelivery.find(method => method.value === Number(methodKey));
      }
    } catch (error) {
      logger.error(error);
    }
  } else {
    deliveryMethodObject = methodOfDelivery.find(
      method => method.value === Number(deliveryMethodId),
    );
  }
  return deliveryMethodObject;
};

export const getDeliveryMethodLabel = (
  shippingMethod: string,
  deliveryMethodId: number,
): string => {
  const defaultDeliveryMethodLabel = '-';
  const deliveryMethodObject = getDeliveryMethodObject(deliveryMethodId, shippingMethod);
  return deliveryMethodObject && deliveryMethodObject.label
    ? deliveryMethodObject.label
    : defaultDeliveryMethodLabel;
};

export const getShippingMethodLabel = (value: any) => {
  let shippingMethodList = '-';
  /**
   * Converting shipping method options to a key(method.value)->value(method.label)
   * dict for fast lookup.
   */
  const shippingMethodsLookup = Object.values(deliveryShippingMethodMap).reduce(
    (accum, shippinMethodsList) => {
      shippinMethodsList.forEach((shippingMethod: any) => {
        accum[shippingMethod.value] = shippingMethod.label;
      });
      return accum;
    },
    {} as any,
  );
  try {
    // It throws an exception if not able to parse
    const shippingMethodsObj = JSON.parse(value);
    // It throws an exception if Object.keys arg is null
    const shippingMethodsKeys = Object.keys(shippingMethodsObj);
    if (shippingMethodsKeys.length) {
      shippingMethodList = shippingMethodsKeys
        .map(shippingMethodKey => shippingMethodsLookup[shippingMethodKey] || '-')
        .join('\n');
    }
  } catch (error) {
    logger.error(error);
  }
  return shippingMethodList;
};
const fancyLabel = (pharm: any) => (
  <Grid container>
    <Grid item xs={11}>
      {pharm.name}
    </Grid>
    <Grid item xs={1} style={{ height: '22px', marginTop: '-3px' }}>
      <IconWithTooltip
        type="check"
        tooltip="Patient Preferred Dispensing Pharmacy"
        inlineStyle={{ fontSize: '20px' }}
      />
    </Grid>
  </Grid>
);

export const getDispensingPharmacyOptions = (
  internalPharmacies: any,
  preferredRxDeliveryPharamcy: any,
) => {
  return (
    internalPharmacies?.map((pharm: any) => {
      if (
        preferredRxDeliveryPharamcy?.name === pharm.name &&
        preferredRxDeliveryPharamcy?.npi === pharm.npi
      ) {
        return {
          label: fancyLabel(pharm),
          value: {
            dispensing_pharmacy_npi: pharm.npi,
            dispensing_pharmacy_name: pharm.name,
            is_courier_integrated: pharm.is_courier_integrated,
            is_ups_integrated: pharm.is_ups_integrated,
          },
        };
      }
      return {
        label: pharm.name,
        value: {
          dispensing_pharmacy_npi: pharm.npi,
          dispensing_pharmacy_name: pharm.name,
          is_courier_integrated: pharm.is_courier_integrated,
          is_ups_integrated: pharm.is_ups_integrated,
        },
      };
    }) ?? []
  );
};

export const buildPerTaskInfo = (
  fillCycles: IFillCycles,
  therapy: ITherapy,
  task: IFillCoordinationTask,
): {
  copayAmount: any;
  daysSupply: any;
  needsbyDate: any;
  nextNeedsByDate: any;
  onHandQty: any;
  patientMissedDoses: any;
  dispenseQty: any;
  serviceGroupId: any;
  authorizationCode: any;
  rx_number: any;
  drop_ship_med: any;
} => {
  let therapyOrFillCycle: TherapyFillCycle = therapy;
  if (task?.fill_cycle_number) {
    const matchingFillCycle = fillCycles[task.therapy_id]?.[task.fill_cycle_number];
    therapyOrFillCycle = matchingFillCycle;
  }

  const parsedNeedsByDate = moment.utc(therapyOrFillCycle?.needsby_date).startOf('day');

  // on hand qty is calculated based on today's date minus needsby date,
  // is 0 if needsbydate is in the past
  const today = moment.utc();
  const onHandQty = parsedNeedsByDate.isBefore(moment.utc(), 'day')
    ? 0
    : Math.abs(today.startOf('day').diff(parsedNeedsByDate.startOf('day'), 'days'));
  const daysSupply = (therapyOrFillCycle && therapyOrFillCycle.days_supply) || 0;
  const nextNeedsByDate = moment
    .utc()
    .startOf('day')
    .add(daysSupply + (onHandQty || 0), 'days');

  const result = {
    copayAmount: task?.copay_amount,
    daysSupply: daysSupply,
    needsbyDate: parsedNeedsByDate,
    onHandQty: onHandQty,
    patientMissedDoses: definedBoolean(task?.patient_missed_doses)
      ? task?.patient_missed_doses
      : null,
    nextNeedsByDate: nextNeedsByDate,
    dispenseQty: task?.dispense_qty,
    serviceGroupId: task?.service_group_id,
    authorizationCode: task?.authorization_code,
    rx_number: task?.rx_number,
    drop_ship_med: task?.drop_ship_med,
  };

  return result;
};

// Select cold chain when any therapy requires it
export const getDefaultColdChain = (therapies: ITherapy[]) => {
  return therapies?.some(therapy => therapy?.cold_chain_packing_required ?? false) ?? false;
};

export const coldChainDisabled = (
  task: IFillCoordinationTask,
  therapies: ITherapy[],
  siblingTasks: { task: IFillCoordinationTask; therapy: ITherapy }[],
): boolean | undefined => {
  const therapiesSelected: ITherapy[] = [];

  siblingTasks.map(({ task, therapy }) => {
    if (task.therapy_id === therapy.id) {
      therapiesSelected.push(therapy);
      return therapiesSelected;
    }
    return null;
  });

  therapies.find((therapy: ITherapy) => {
    if (therapy.id === task.therapy_id) {
      therapiesSelected.push(therapy);
      return therapiesSelected;
    }
    return null;
  });

  return getDefaultColdChain(therapiesSelected);
};

export const getDefaultSideEffectsInt = (sideEffectType: SideEffectType): IShortIntervention => ({
  status_id: REQUIRED_INT_STATUS_ID,
  reaction: null,
  suspected_caused_by: null,
  category_id: InterventionCategoryId.Patient,
  type_id:
    sideEffectType === SideEffectType.Common
      ? InterventionTypeId.Adr
      : InterventionTypeId.Adherence,
  details: '',
  sideeffect_type: sideEffectType,
});

export const buildNewInterventionsPayload = (
  newInterventions: IShortIntervention[],
): IInterventionPayload[] =>
  newInterventions
    .filter((newInt: IShortIntervention) => {
      if (newInt.sideeffect_type === SideEffectType.Common) {
        return Boolean(newInt.reaction && newInt.suspected_caused_by);
      }
      if (newInt.sideeffect_type === SideEffectType.MissedMedicationDose) {
        return Boolean(newInt.suspected_caused_by);
      }
      return false;
    })
    .map((newInt: IShortIntervention) => {
      const payloadInt: IInterventionPayload = {
        status_id: newInt.status_id,
        type_id: newInt.type_id as number,
        category_id: newInt.category_id as number,
        details: newInt.details as string,
        therapy_id: null,
        medication_ids: null,
      };

      if (newInt.suspected_caused_by?.type === 'med' && newInt.suspected_caused_by?.id) {
        payloadInt.medication_ids = [newInt.suspected_caused_by.id];
      } else if (newInt.suspected_caused_by?.type === 'therapy' && newInt.suspected_caused_by?.id) {
        payloadInt.therapy_id = newInt.suspected_caused_by.id;
      }

      return payloadInt;
    });

export const nameOfFormFieldsPerTask = (
  fieldName: keyof IFillCoordinationEditFormFields,
  task: IFillCoordinationTask,
) => ReduxFormUtil.getFieldNamePerTask(fieldName, task);

export const concatWithDefinedValidator = (
  condition: boolean,
  validators?: Validator[],
): Validator[] => {
  if (condition === true) {
    return validators?.length ? [defined, ...validators] : [defined];
  }
  return validators?.length ? validators : [];
};

export const updateWeatherBasedOnAddress = (
  fullAddress: string,
  shipDate: moment.Moment | undefined,
  changeFn: (field: string, value: any) => void,
  onSuccess?: () => void,
): void => {
  if (typeof fullAddress !== 'string') {
    changeFn(nameOfFormFields('weather'), undefined);
    changeFn(nameOfFormFields('location'), undefined);
    return;
  }
  const service = new WeatherService();
  const lastCommaIndex = fullAddress.lastIndexOf(', ');
  const zip = fullAddress.substr(lastCommaIndex + 2);
  const yesterdayShipDate = (shipDate ? moment(shipDate) : moment()).subtract(1, 'day').toDate();

  service
    .getResponse(zip, 16, yesterdayShipDate)
    .then(result => {
      if (result && result.city) {
        changeFn(nameOfFormFields('weather'), result);
        changeFn(nameOfFormFields('location'), result?.city);
        if (onSuccess) {
          onSuccess();
        }
      } else {
        changeFn(nameOfFormFields('weather'), undefined);
        changeFn(nameOfFormFields('location'), undefined);
      }
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        console.log('error in weather request: ', err.response.data.message);
      } else {
        console.log('error requesting weather for zip: ', zip);
      }
      changeFn(nameOfFormFields('weather'), undefined);
      changeFn(nameOfFormFields('location'), undefined);
    });
};

export const buildFieldSettings = (
  selectedStatus: number,
  patient: IPatient,
  task: IFillCoordinationTask & { status: any },
  methodIsChartReview: any,
  deliveryMethodVal: { value: DeliveryMethodValue },
  componentMode: EditComponentMode,
  therapies: Record<TherapyId, ITherapy> | undefined,
  siblingTaskTherapies: { task: IFillCoordinationTask; therapy: ITherapy }[] | undefined,
  statusReason: string,
): Record<
  | keyof IFillCoordinationEditFormFields
  | 'deliveryMethod'
  | 'drug_name'
  | 'order_type_address_pickup'
  | 'titles'
  | 'fieldLabels'
  | 'isDelivery'
  | 'isCourier'
  | 'totalCopayAmount'
  | 'isInProgressOrNeedsReviewOrComplete'
  | 'deliveryMethodVal'
  | 'deliveryValue',
  boolean | any
> & { buttons: { addPaymentMethod: boolean } } => {
  let therapyIsSpecial = false;
  let someSiblingsSpecial = false;

  if (therapies) {
    const taskTherapy = therapies[task.therapy_id];
    therapyIsSpecial = taskTherapy && taskTherapy.is_specialty;
  }

  if (siblingTaskTherapies && siblingTaskTherapies.length) {
    someSiblingsSpecial = siblingTaskTherapies.some(taskTherapy => {
      return taskTherapy?.therapy.is_specialty;
    });
  }

  const specialtyTherapyIsSelected = therapyIsSpecial || someSiblingsSpecial;

  const isComplete = selectedStatus === FillCoordinationStatus.Completed;
  const isRequired = selectedStatus === FillCoordinationStatus.Required;
  const isInProgressStrict = FillCoordinationStatus.In_Progress === selectedStatus;
  const isInProgress = [
    FillCoordinationStatus.In_Progress,
    FillCoordinationStatus.In_Progress_Call_Patient,
    FillCoordinationStatus.In_Progress_Patient_Response_Ready,
    FillCoordinationStatus.In_Progress_Waiting_for_Patient_Response,
  ].includes(selectedStatus);
  const isCanceled = canceledStatuses.includes(selectedStatus);
  const taskStatus = task.status;
  const currentStatusDifferent = selectedStatus !== taskStatus;

  const deliveryValue = (deliveryMethodVal || {}).value;
  const isInProgressOrNeedsReviewOrComplete = isInProgress || isComplete;
  const isPickup =
    isInProgressOrNeedsReviewOrComplete && deliveryValue === DeliveryMethodValue.PickUp;
  const isDelivery = isInProgressOrNeedsReviewOrComplete && isDeliveryMethodSelected(deliveryValue);
  const isCourier = isInProgressOrNeedsReviewOrComplete && isCourierMethodSelected(deliveryValue);

  return {
    isInProgressOrNeedsReviewOrComplete,
    deliveryValue,
    deliveryMethodVal,
    isDelivery: isDelivery,
    isCourier,
    apply_choice_to_therapies_and_FCs: undefined,
    deliveryMethod: {
      isPickup,
      isDelivery,
      isCourier,
    },
    buttons: {
      addPaymentMethod: isInProgressOrNeedsReviewOrComplete,
    },
    titles: {
      delivery: isInProgressOrNeedsReviewOrComplete,
      fillCoordination: isInProgressOrNeedsReviewOrComplete,
    },
    address: isDelivery || isCourier,
    additional_medications: isInProgressOrNeedsReviewOrComplete,
    ancillary_supplies: isInProgressOrNeedsReviewOrComplete,
    canceled_status_id: isCanceled && currentStatusDifferent,
    changes_in_medication:
      isInProgressOrNeedsReviewOrComplete && currentStatusDifferent && specialtyTherapyIsSelected,
    cold_chain_packing_required: isInProgressOrNeedsReviewOrComplete,
    copay_amount: isInProgressOrNeedsReviewOrComplete,
    courier_type: isInProgress && isCourier,
    days_supply: isInProgressOrNeedsReviewOrComplete,
    delivery_dt: isInProgressOrNeedsReviewOrComplete,
    dispensing_pharmacy: isInProgressOrNeedsReviewOrComplete,
    dispense_qty: isInProgressOrNeedsReviewOrComplete,
    drug_name: isInProgressOrNeedsReviewOrComplete && currentStatusDifferent,
    email: isInProgressOrNeedsReviewOrComplete,
    followup_dt: (isRequired || isInProgress) && currentStatusDifferent,
    hospital_visit:
      isInProgressOrNeedsReviewOrComplete && currentStatusDifferent && specialtyTherapyIsSelected,
    is_notice_privacy_practices_sent:
      isInProgressOrNeedsReviewOrComplete && !patient.privacy_policy_sent,
    in_progress_reason: isInProgressStrict,
    medicaid_only: isInProgressOrNeedsReviewOrComplete,
    method: isInProgressOrNeedsReviewOrComplete,
    needsby_date: isInProgressOrNeedsReviewOrComplete,
    new_allergies:
      isInProgressOrNeedsReviewOrComplete && currentStatusDifferent && specialtyTherapyIsSelected,
    new_medical_conditions:
      (isComplete || isInProgress) && currentStatusDifferent && specialtyTherapyIsSelected,
    new_infections:
      (isComplete || isInProgress) && currentStatusDifferent && specialtyTherapyIsSelected,
    next_needsby_date: isInProgressOrNeedsReviewOrComplete,
    notes:
      (isInProgressOrNeedsReviewOrComplete || isRequired || isCanceled) &&
      componentMode === EditComponentMode.ChangeStatus,
    on_hand_qty: isInProgressOrNeedsReviewOrComplete,
    order_notes: isInProgressOrNeedsReviewOrComplete,
    order_type_address_pickup: isPickup,
    order_type_address: true,
    one_time_fill: null,
    patient_acknowledges: isDelivery,
    patient_advised_pickup: isPickup,
    patient_missed_doses:
      !methodIsChartReview && isInProgressOrNeedsReviewOrComplete && currentStatusDifferent,
    patient_questions:
      !methodIsChartReview &&
      isInProgressOrNeedsReviewOrComplete &&
      currentStatusDifferent &&
      specialtyTherapyIsSelected,
    payment_method_on_file: isInProgressOrNeedsReviewOrComplete,
    preferred_phone_number: isInProgressOrNeedsReviewOrComplete,
    preferred_rx_delivery_method: isInProgressOrNeedsReviewOrComplete && currentStatusDifferent,
    rx_number: isInProgressOrNeedsReviewOrComplete,
    service_group_id: isInProgressOrNeedsReviewOrComplete,
    ship_date: isDelivery || isCourier,
    shipping_method: isDelivery,
    side_effects:
      isInProgressOrNeedsReviewOrComplete && currentStatusDifferent && specialtyTherapyIsSelected,
    signature_required: isInProgressOrNeedsReviewOrComplete,
    special_instructions: isInProgressOrNeedsReviewOrComplete,
    spoke_to_patient_dt: isInProgressOrNeedsReviewOrComplete && currentStatusDifferent,
    status_id: isCanceled && currentStatusDifferent,
    to_md: isCourier,
    to_patient: isCourier,
    urgent: isInProgressOrNeedsReviewOrComplete,
    weather: isInProgressOrNeedsReviewOrComplete && (isDelivery || isCourier),
    location: isInProgressOrNeedsReviewOrComplete && (isDelivery || isCourier),
    welcome_kit_sent: isInProgressOrNeedsReviewOrComplete && !patient.welcome_kit_sent,
    wk_pp_received: isInProgressOrNeedsReviewOrComplete,
    ups_packaging_type: isDelivery,
    therapy_ids_to_remove: false,
    fc_ids_in_bundle: false,
    drop_ship_med: isInProgressOrNeedsReviewOrComplete,

    // legacy fields
    additional_reason: isCanceled && statusReason === 'Other',
    completed_dt: null,
    copay_note: null,
    last_checked_dt: false,
    order_last_received_dt: false,

    // document fields
    [UPLOAD_DOC_FILENAME_FIELD]: undefined,
    [UPLOAD_DOC_FILETYPE_FIELD]: undefined,
    [UPLOAD_DOC_FILENOTE_FIELD]: undefined,

    fieldLabels: {
      address: isCourier ? 'Courier To Address' : undefined,
    },
    totalCopayAmount: isInProgressOrNeedsReviewOrComplete,
    payment_method_ids: isInProgressOrNeedsReviewOrComplete,
    authorization_code: true,
  };
};

export const deliveryMethod = (value: number | null) => {
  switch (value) {
    case DeliveryMethodValue.PickUp:
      return DeliveryMethodLabel.PickUp;
    case DeliveryMethodValue.Ups:
      return DeliveryMethodLabel.Ups;
    case DeliveryMethodValue.Courier:
      return DeliveryMethodLabel.Courier;
    case DeliveryMethodValue.FedEx:
      return DeliveryMethodLabel.FedEx;
    case DeliveryMethodValue.Usps:
      return DeliveryMethodLabel.Usps;
    default:
      return 'Not Configured';
  }
};

export const buildButtonTexts = (
  selectedStatus:
    | typeof FILL_COORDINATION_STATUS_CANCELED
    | typeof FILL_COORDINATION_STATUS_COMPLETED
    | typeof FILL_COORDINATION_STATUS_REQUIRED,
) => {
  return {
    save: selectedStatus === FILL_COORDINATION_STATUS_COMPLETED ? 'Save & Preview' : 'Save',
  };
};

const getPreferredRxDeliveryMethod = (
  patient: IPatient,
  task: IFillCoordinationTask,
  methods: any[],
) => {
  if (task.preferred_rx_delivery_method && task.preferred_rx_delivery_method > 0) {
    return methods.find((method: any) => method.value === task.preferred_rx_delivery_method);
  }
  // The next 2 options are only possible when the FC used self service. -1 is when the
  // patient removed a non-prn, specialty therapy from therapy and therefore the patient was not
  // able to answer the delivery method question. -2 is when the patient selected delivery
  // and needs to deliver to a new address.
  if (task.preferred_rx_delivery_method && task.preferred_rx_delivery_method === -1) {
    return {
      value: -1,
      label: 'Contact patient for delivery or pickup choice',
    };
  }
  if (task.preferred_rx_delivery_method && task.preferred_rx_delivery_method === -2) {
    return {
      value: -2,
      label: 'Patient selected Delivery, choose a method',
    };
  }

  const patientPreferredMethod = methods.find(
    (method: any) => method.value === patient.preferred_rx_delivery_method,
  );

  const preferredMethod = getDeliveryMethodObject(
    task.preferred_rx_delivery_method,
    task.shipping_method,
  );

  return patientPreferredMethod || preferredMethod || methods[0];
};

const getShippingMethod = (currentMethod: any) => {
  const parsedJson = JSON.parse(currentMethod);
  if (parsedJson) {
    const keys = Object.keys(parsedJson);
    return keys[0];
  }
  return undefined;
};

const triState = (value: boolean | number | undefined | null) => {
  if (value === 1 || value === true) {
    return 1;
  }
  if (value === 0 || value === false) {
    return 0;
  }
  return undefined;
};

export const getSignatureRequiredValue = (
  tasks: IFillCoordinationTask[],
  therapies: Record<number, ITherapy>,
  patient: IPatient,
) => {
  const primaryTask = tasks[0];
  const statusId = tasks[0].status_id;
  const patientSigReqValue = triState(patient.signature_required);
  const therapyIdsInBundle = tasks.map(task => task.therapy_id);
  const removedTherapyIds = primaryTask.therapy_ids_to_remove || [];
  const containsAControlledSubstance = therapyIdsInBundle
    .filter(id => !removedTherapyIds.includes(id))
    .reduce(
      (acc, therapyId) =>
        acc ||
        controlledSubstanceList.includes(Number(therapies[therapyId].controlled_substance_code)),
      false,
    );

  const sigRequired = window.FEATURES.sig_required;
  const tempSigReqValue = sigRequired || containsAControlledSubstance ? 1 : undefined;

  switch (statusId) {
    case FillCoordinationStatus.Required:
    case FillCoordinationStatus.In_Progress_Patient_Response_Ready:
    case FillCoordinationStatus.In_Progress_Call_Patient:
      return triState(tempSigReqValue || primaryTask.signature_required || patientSigReqValue);
    case FillCoordinationStatus.Completed:
    case FillCoordinationStatus.Canceled:
      return primaryTask.signature_required;
    case FillCoordinationStatus.In_Progress:
    case FillCoordinationStatus.NeedsReview:
      return triState(primaryTask.signature_required || tempSigReqValue || patientSigReqValue);
    default:
      return sigRequired ? 1 : undefined;
  }
};

export const buildInitialValues = (
  tasks: IFillCoordinationTask[],
  props: { serviceGroups: any },
  patient: IPatient,
  therapies: Record<TherapyId, ITherapy>,
  contactList: IContactList,
  fillCycles: IFillCycles,
  siblingTaskTherapies: { task: any; therapy: any }[],
  upsPackagingTypes: IUpsPackagingTypes[],
  preferredRxDeliveryPharmacy?: any,
  internalPharmacies?: any,
): Partial<IFillCoordinationEditFormFields> & { preferred_rx_delivery_pharmacy?: any } => {
  if (!tasks || !tasks.length) {
    return {
      additional_reason: undefined,
      followup_dt: undefined,
      last_checked_dt: undefined,
      preferred_rx_delivery_method: undefined,
      status_id: undefined,
    };
  }

  const primaryTask = tasks[0];
  const primaryTaskStatusId = tasks[0].status_id;

  // #region preferred email stuff
  const preferredEmail = getPreferredEmailAddress(patient, contactList);
  const allEmails = getFullEmailList(patient, contactList) as { label: string }[];
  const taskPreferredEmail = primaryTask.email;
  const matchingEmail = allEmails.find(x => x.label === taskPreferredEmail);
  const fcPreferredEmail = matchingEmail
    ? { label: matchingEmail.label, value: matchingEmail.label }
    : preferredEmail
    ? { label: preferredEmail, value: preferredEmail }
    : undefined;
  // #endregion

  // #region  preferred phone stuff
  const patientPreferredPhone = getPreferredPhoneNumber(patient, contactList);
  const allPhones = getFullPhoneList(patient, contactList);

  const taskPreferredPhone = primaryTask.preferred_phone_number;
  const matchingPhone = (allPhones as { label: string }[]).find(
    x => x.label === taskPreferredPhone,
  );
  const fcPreferredPhone = matchingPhone
    ? { label: matchingPhone.label, value: matchingPhone.label }
    : patientPreferredPhone
    ? { label: patientPreferredPhone, value: patientPreferredPhone }
    : undefined;
  // #endregion

  // #region address stuff
  const preferredAddress = getPreferredAddressForFC(patient, contactList);
  const allAddresses = getFullAddressList(patient, contactList);
  // #endregion

  const parsedAncillarySupplies = primaryTask.ancillary_supplies
    ? Object.keys(JSON.parse(primaryTask.ancillary_supplies))
    : undefined;

  const formattedOtcMedications: IAdditionalMedication[] = [];
  const additionalMedications = primaryTask.additional_medications
    ? JSON.parse(primaryTask.additional_medications)
    : undefined;

  if (additionalMedications) {
    additionalMedications.forEach((medication: any) => {
      formattedOtcMedications.push({ label: medication.label, value: medication.value });
    });
  }

  /**
   * For fields that potentially exist for multiple tasks, need to initialize those differently.
   * Here we just initialize with the original task's values.
   */

  const perTaskValues: Partial<IFillCoordinationEditFormFields> = tasks
    .concat(siblingTaskTherapies.map(x => x.task))
    .reduce<Partial<IFillCoordinationEditFormFields>>((acc, task: IFillCoordinationTask) => {
      const matchingTherapy = therapies[task.therapy_id];
      const taskInfo = buildPerTaskInfo(fillCycles, matchingTherapy, task);

      acc.copay_amount = { ...acc.copay_amount, [task.id]: taskInfo.copayAmount };
      acc.days_supply = { ...acc.days_supply, [task.id]: taskInfo.daysSupply };
      acc.needsby_date = { ...acc.needsby_date, [task.id]: taskInfo.needsbyDate };
      acc.on_hand_qty = { ...acc.on_hand_qty, [task.id]: taskInfo.onHandQty };
      acc.next_needsby_date = { ...acc.next_needsby_date, [task.id]: taskInfo.nextNeedsByDate };
      acc.dispense_qty = { ...acc.dispense_qty, [task.id]: taskInfo.dispenseQty };
      acc.service_group_id = { ...acc.service_group_id, [task.id]: taskInfo.serviceGroupId };
      acc.authorization_code = { ...acc.authorization_code, [task.id]: taskInfo.authorizationCode };
      acc.rx_number = { ...acc.rx_number, [task.id]: taskInfo.rx_number };
      acc.drop_ship_med = { ...acc.drop_ship_med, [task.id]: matchingTherapy?.drop_ship_med };

      return acc;
    }, {});

  let preferredAddressDropdownLabelValue:
    | {
        label: any;
        value: any;
        isPreferred: boolean;
        source: string | null;
        contact_id?: number | undefined | null;
      }
    | undefined;
  // this is looking for an address match on the patient
  if (
    primaryTask.preferred_shipping_address &&
    allAddresses
      .map((x: any) => x.label?.toLowerCase())
      .includes(primaryTask.preferred_shipping_address?.toLowerCase())
  ) {
    const primaryTaskAddress = allAddresses
      .map((address: any, index: number) => ({
        label: address.label,
        rawAddress: address.rawAddress,
        isPreferred: index === 0,
        source: address.source,
        ...(address.contactId ? { contact_id: address.contactId } : {}),
      }))
      .find(
        a => a.label?.toLowerCase() === primaryTask.preferred_shipping_address?.toLowerCase(),
      ) || {
      label: 'Select...',
      rawAddress: null,
      isPreferred: false,
      source: null,
    };
    preferredAddressDropdownLabelValue = {
      label: primaryTaskAddress.label,
      value: primaryTaskAddress.rawAddress,
      isPreferred: primaryTaskAddress.isPreferred,
      source: primaryTaskAddress.source,
      ...(primaryTaskAddress.contact_id ? { contact_id: primaryTaskAddress.contact_id } : {}),
    };
  } else if (
    // this is looking for an address match on a contact
    primaryTask.preferred_shipping_address &&
    allAddresses.find(
      (x: any) =>
        x.label &&
        x.label.split(' - ')[2]?.toLowerCase() ===
          primaryTask.preferred_shipping_address?.split(' - ')[2]?.toLowerCase(),
    )
  ) {
    const primaryTaskAddress = allAddresses
      .map((address: any, index: number) => ({
        label: address.label,
        rawAddress: address.rawAddress,
        isPreferred: index === 0,
        source: 'contact',
        contact_id: address.id,
      }))
      .find(
        a =>
          a.label &&
          a.label.split(' - ')[2]?.toLowerCase() ===
            primaryTask.preferred_shipping_address?.split(' - ')[2]?.toLowerCase(),
      ) || {
      label: 'Select...',
      rawAddress: null,
      isPreferred: false,
      source: null,
      contact_id: null,
    };
    preferredAddressDropdownLabelValue = {
      label: primaryTaskAddress.label,
      value: primaryTaskAddress.rawAddress,
      isPreferred: primaryTaskAddress.isPreferred,
      source: primaryTaskAddress.source,
      contact_id: primaryTaskAddress.contact_id,
    };
  } else if (
    primaryTask.method === 4 &&
    primaryTask.preferred_rx_delivery_method !== 1 &&
    !primaryTask.preferred_shipping_address &&
    !primaryTask.patient_requested_therapy_list_changes
  ) {
    preferredAddressDropdownLabelValue = {
      label: 'Contact patient for new delivery address',
      value: -1,
      isPreferred: true,
      source: null,
    };
  } else if (
    primaryTask.method === 4 &&
    primaryTask.preferred_rx_delivery_method !== 1 &&
    !primaryTask.preferred_shipping_address &&
    primaryTask.patient_requested_therapy_list_changes
  ) {
    preferredAddressDropdownLabelValue = {
      label: 'Contact patient to collect delivery address',
      value: -1,
      isPreferred: true,
      source: null,
    };
  } else if (preferredAddress.label) {
    preferredAddressDropdownLabelValue = {
      label: preferredAddress.label,
      value: preferredAddress.rawAddress,
      isPreferred: true,
      source: preferredAddress.source,
    };
  } else {
    preferredAddressDropdownLabelValue = {
      label: 'Select...',
      value: null,
      isPreferred: false,
      source: null,
    };
  }

  const spokeToPatientDt: undefined | moment.Moment =
    convertToArborDate(primaryTask.spoke_to_patient_dt).getCustomerMoment() ||
    moment().startOf('day');
  const tasksTherapyIds = tasks.map(task => task.therapy_id);

  const dispensingPharmacyOptions: { label: any; value: any }[] = getDispensingPharmacyOptions(
    internalPharmacies,
    null,
  );

  let dispensingPharmacy: any;
  if (dispensingPharmacyOptions.length === 1) {
    dispensingPharmacy = dispensingPharmacyOptions[0].value;
  } else if (
    tasks.length === 1 &&
    tasks[0] &&
    tasks[0].dispensing_pharmacy_name !== null &&
    tasks[0].dispensing_pharmacy_npi !== null
  ) {
    dispensingPharmacy = {
      dispensing_pharmacy_npi: tasks[0].dispensing_pharmacy_npi,
      dispensing_pharmacy_name: tasks[0].dispensing_pharmacy_name,
      is_courier_integrated: !!(
        internalPharmacies.find(
          (pharm: IPharmacy) => pharm.npi === tasks[0].dispensing_pharmacy_npi,
        ) || {}
      ).is_courier_integrated,
      is_ups_integrated: !!(
        internalPharmacies.find(
          (pharm: IPharmacy) => pharm.npi === tasks[0].dispensing_pharmacy_npi,
        ) || {}
      ).is_ups_integrated,
    };
  } else if (preferredRxDeliveryPharmacy?.npi) {
    const preferredPharmacy = internalPharmacies?.find(
      (pharm: any) => pharm.npi === preferredRxDeliveryPharmacy.npi,
    );
    dispensingPharmacy = {
      dispensing_pharmacy_npi: preferredPharmacy.npi,
      dispensing_pharmacy_name: preferredPharmacy.name,
      is_courier_integrated: preferredPharmacy.is_courier_integrated,
      is_ups_integrated: preferredPharmacy.is_ups_integrated,
    };
  } else {
    const allTherapyValues = Object.values(therapies);
    const selectedTherapyIds = tasks.map(task => task.therapy_id).filter(item => !!item);
    const sampleTherapy = therapies[selectedTherapyIds[0]];
    const therapiesInSelection = allTherapyValues.filter(therapy =>
      selectedTherapyIds.includes(therapy.id),
    );
    const allTherapiesSame = therapiesInSelection.every(
      therapy =>
        therapy.dispensing_pharmacy_name === sampleTherapy.dispensing_pharmacy_name &&
        therapy.dispensing_pharmacy_npi === sampleTherapy.dispensing_pharmacy_npi,
    );
    if (allTherapiesSame) {
      dispensingPharmacy = {
        dispensing_pharmacy_npi: sampleTherapy?.dispensing_pharmacy_npi,
        dispensing_pharmacy_name: sampleTherapy?.dispensing_pharmacy_name,
        is_courier_integrated: !!(
          internalPharmacies.find(
            (pharm: IPharmacy) => pharm.npi === sampleTherapy?.dispensing_pharmacy_npi,
          ) || {}
        ).is_courier_integrated,
        is_ups_integrated: !!(
          internalPharmacies.find(
            (pharm: IPharmacy) => pharm.npi === sampleTherapy?.dispensing_pharmacy_npi,
          ) || {}
        ).is_ups_integrated,
      };
    }
  }

  // This is for an edge case where the pharmacy name was changed in the database. This will ensure the name is updated on the FC.
  if (dispensingPharmacy?.dispensing_pharmacy_npi) {
    const currentDispensingPharmacy = internalPharmacies.find(
      (item: any) => item.npi === dispensingPharmacy.dispensing_pharmacy_npi,
    );
    if (currentDispensingPharmacy) {
      dispensingPharmacy.dispensing_pharmacy_name = currentDispensingPharmacy.name;
    } else {
      dispensingPharmacy = null;
    }
  } else {
    dispensingPharmacy = null;
  }

  const primaryTaskUpsPackagingType = (upsPackagingTypes ?? []).find(
    packagingType => packagingType.id === primaryTask.ups_packaging_type,
  );
  const upsPackagingType: ILabelValueNumber | undefined = primaryTaskUpsPackagingType
    ? { label: primaryTaskUpsPackagingType.packaging_type, value: primaryTaskUpsPackagingType.id }
    : undefined;

  const taskTherapies = Object.values(therapies).filter(therapy =>
    tasksTherapyIds.includes(therapy.id),
  );
  const defaultColdChainPacking = getDefaultColdChain(taskTherapies);
  const patientPrimaryPaymentMethod = patient.payment_methods?.find(({ rank }) => rank === 1)?.id;
  let paymentMethodIds = patientPrimaryPaymentMethod ? [patientPrimaryPaymentMethod] : [];
  if (primaryTask.order_id) {
    paymentMethodIds = primaryTask.payment_method_ids ?? [];
  }
  if (
    primaryTaskStatusId === FillCoordinationStatus.In_Progress_Call_Patient ||
    primaryTaskStatusId === FillCoordinationStatus.In_Progress_Patient_Response_Ready
  ) {
    paymentMethodIds = [primaryTask.patient_payment_id_choice as number];
  }
  if (primaryTaskStatusId === FillCoordinationStatus.In_Progress) {
    paymentMethodIds = primaryTask.in_progress_payment_ids ?? [];
  }

  const returnObj: Partial<IFillCoordinationEditFormFields> & {
    preferred_rx_delivery_pharmacy?: any;
  } = {
    ...perTaskValues,
    additional_medications: formattedOtcMedications,
    additional_reason: primaryTask.additional_reason ? primaryTask.additional_reason : '',
    address: preferredAddressDropdownLabelValue,
    ancillary_supplies:
      parsedAncillarySupplies && parsedAncillarySupplies.length > 0
        ? parsedAncillarySupplies.join(',')
        : undefined,
    changes_in_medication: definedBoolean(primaryTask.changes_in_medication)
      ? primaryTask.changes_in_medication
      : undefined,
    welcome_kit_sent: definedBoolean(primaryTask.welcome_kit_sent)
      ? primaryTask.welcome_kit_sent
      : undefined,
    wk_pp_received: definedBoolean(primaryTask.wk_pp_received)
      ? primaryTask.wk_pp_received
      : undefined,
    patient_missed_doses: definedBoolean(primaryTask.patient_missed_doses)
      ? primaryTask.patient_missed_doses
      : undefined,
    is_notice_privacy_practices_sent: definedBoolean(primaryTask.is_notice_privacy_practices_sent)
      ? primaryTask.is_notice_privacy_practices_sent
      : undefined,
    cold_chain_packing_required: primaryTask.cold_chain_packing_required || defaultColdChainPacking,
    completed_dt:
      convertToArborDate(primaryTask.completed_dt, true).getCustomerMoment() || undefined,
    copay_note: primaryTask.copay_note ? primaryTask.copay_note : '',
    courier_type: primaryTask.courier_type ? primaryTask.courier_type : undefined,
    dispensing_pharmacy: dispensingPharmacy,
    delivery_dt: convertToArborDate(primaryTask.delivery_dt).getCustomerMoment() || undefined,
    email: fcPreferredEmail,
    followup_dt: convertToArborDate(primaryTask.followup_dt).getCustomerMoment() || undefined,
    hospital_visit: definedBoolean(primaryTask.hospital_visit)
      ? primaryTask.hospital_visit
      : undefined,
    last_checked_dt:
      convertToArborDate(primaryTask.last_checked_dt, true).getCustomerMoment() || undefined,
    medicaid_only: primaryTask.medicaid_only,
    method: primaryTask.method ? primaryTask.method : undefined,
    new_allergies: definedBoolean(primaryTask.new_allergies)
      ? primaryTask.new_allergies
      : undefined,
    new_medical_conditions: definedBoolean(primaryTask.new_medical_conditions)
      ? primaryTask.new_medical_conditions
      : undefined,
    new_infections: definedBoolean(primaryTask.new_infections)
      ? primaryTask.new_infections
      : undefined,
    order_last_received_dt:
      convertToArborDate(primaryTask.order_last_received_dt, true).getCustomerMoment() || undefined,
    order_notes: primaryTask.order_notes,
    order_type_address: primaryTask.order_type_address,
    patient_acknowledges: primaryTask.patient_acknowledges,
    patient_questions: definedBoolean(primaryTask.patient_questions)
      ? primaryTask.patient_questions
      : undefined,
    payment_method_on_file:
      primaryTask.payment_method_on_file === null
        ? Boolean(patient.payment_methods?.length)
        : primaryTask.payment_method_on_file,
    preferred_phone_number: fcPreferredPhone,
    preferred_rx_delivery_method: getPreferredRxDeliveryMethod(
      patient,
      primaryTask,
      methodOfDelivery,
    ),
    preferred_rx_delivery_pharmacy: preferredRxDeliveryPharmacy,
    ship_date: primaryTask.ship_date ? moment.utc(primaryTask.ship_date) : undefined,
    shipping_method: getShippingMethod(primaryTask.shipping_method),
    side_effects: definedBoolean(primaryTask.side_effects) ? primaryTask.side_effects : undefined,
    signature_required: getSignatureRequiredValue(tasks, therapies, patient),
    special_instructions: primaryTask.special_instructions || patient.rx_delivery_note,
    spoke_to_patient_dt: spokeToPatientDt,
    status_id: primaryTask.status_id,
    urgent: primaryTask.urgent,
    ups_packaging_type: upsPackagingType,
    to_md: primaryTask.to_md,
    to_patient: primaryTask.to_patient,
    payment_method_ids: paymentMethodIds,
    // therapy_ids_to_remove: primaryTask.therapy_ids_to_remove || [],
    // fc_ids_in_bundle: primaryTask.fc_ids_in_bundle || [],
  };

  return returnObj;
};

export const buildPayload = (
  values: Partial<IFillCoordinationEditFormFields>,
  task: IFillCoordinationTask,
  patient: IPatient,
  deliveryMethod: DeliveryMethodValue,
  includeTaskType: boolean,
  statusId: any,
  paymentMethodTypes: IPaymentMethodType[],
  allAddresses: IAllAddress[],
): any => {
  const shippingAddress =
    deliveryMethod !== DeliveryMethodValue.PickUp && values.address ? values.address.value : null;
  const shipMethod = values.shipping_method
    ? buildObjectForJson(values.shipping_method)
    : undefined;
  const {
    copay_amount,
    days_supply,
    followup_dt,
    needsby_date,
    on_hand_qty,
    dispense_qty,
    service_group_id,
    next_needsby_date,
    rx_number,
    drop_ship_med,
    ...sharedValues
  } = values;

  const returnObj: any = {
    ...sharedValues,
    additional_medications: values.additional_medications,
    additional_reason: values.additional_reason || null,
    address: shippingAddress,
    ancillary_supplies: values.ancillary_supplies,
    changes_in_medication: definedBoolean(values.changes_in_medication)
      ? Number(values.changes_in_medication)
      : null,
    cold_chain_packing_recommended: definedBoolean(values.cold_chain_packing_required)
      ? Number(values.cold_chain_packing_required)
      : null,
    cold_chain_packing_required: definedBoolean(values.cold_chain_packing_required)
      ? Number(values.cold_chain_packing_required)
      : null,
    copay_note: values.copay_note,
    days_supply: values.days_supply,
    delivery_dt: values.delivery_dt
      ? convertToArborDate(values.delivery_dt).getUtcDatetime()
      : undefined,
    dispensing_pharmacy_npi: values.dispensing_pharmacy?.dispensing_pharmacy_npi,
    dispensing_pharmacy_name: values.dispensing_pharmacy?.dispensing_pharmacy_name,
    email:
      values.email && typeof values.email === 'string'
        ? (values.email as string)
        : (values.email as { label: string; value: string })?.value,
    hospital_visit: definedBoolean(values.hospital_visit) ? Number(values.hospital_visit) : null,
    id: task.id,
    last_checked_dt: values.last_checked_dt
      ? convertToArborDate(values.last_checked_dt).getUtcDatetime()
      : null,
    medicaid_only: definedBoolean(values.medicaid_only) ? Number(values.medicaid_only) : null,
    method: values.method ? `${values.method}` : '',
    new_allergies: definedBoolean(values.new_allergies) ? Number(values.new_allergies) : null,
    new_infections: definedBoolean(values.new_infections) ? Number(values.new_infections) : null,
    new_medical_conditions: definedBoolean(values.new_medical_conditions)
      ? Number(values.new_medical_conditions)
      : null,
    order_last_received_dt: values.order_last_received_dt
      ? convertToArborDate(values.order_last_received_dt).getUtcDatetime()
      : null,
    order_notes: values.order_notes,
    order_type_address: values.order_type_address,
    patient_acknowledges: definedBoolean(values.patient_acknowledges)
      ? Number(values.patient_acknowledges)
      : null,
    patient_advised_pickup: definedBoolean(values.patient_advised_pickup)
      ? Number(values.patient_advised_pickup)
      : null,
    patient_id: patient.id,
    patient_questions: definedBoolean(values.patient_questions)
      ? Number(values.patient_questions)
      : null,
    payment_method_ids: sharedValues.payment_method_ids?.filter(x => x) || [],
    payment_method_on_file: definedBoolean(values.payment_method_on_file)
      ? Number(values.payment_method_on_file)
      : null,
    preferred_phone_number: values.preferred_phone_number?.value,
    preferred_rx_delivery_method: deliveryMethod,
    preferred_shipping_address: allAddresses?.find((x: any) => x.label === values.address?.label),
    secure_link_id: task.secure_link_id || null,
    service_group_id: values.service_group_id,
    ship_date: values.ship_date ? convertToArborDate(values.ship_date).getUtcDate() : undefined,
    shipping_method:
      deliveryMethod === DeliveryMethodValue.Courier ||
      deliveryMethod === DeliveryMethodValue.PickUp
        ? null
        : shipMethod,
    side_effects: definedBoolean(values.side_effects) ? Number(values.side_effects) : null,
    signature_required: definedBoolean(values.signature_required)
      ? Number(values.signature_required)
      : null,
    special_instructions: values.special_instructions,
    spoke_to_patient_dt: values.spoke_to_patient_dt
      ? convertToArborDate(values.spoke_to_patient_dt).getUtcDatetime()
      : null,
    status_id: statusId,
    therapy_id: task.therapy_id,
    to_md: definedBoolean(values.to_md) ? Number(values.to_md) : null,
    to_patient: definedBoolean(values.to_patient) ? Number(values.to_patient) : null,
    old_status_id: task.status_id,
    urgent: definedBoolean(values.urgent) ? Number(values.urgent) : null,
    weather: values.weather,
    ups_packaging_type: values.ups_packaging_type?.value,
  };

  addTaskSpecificFields(returnObj, values, task.id);

  if (includeTaskType) {
    returnObj.taskType = FC;
  }

  return returnObj;
};

/**
 * Some of the fields on the FC form can be duplicated per therapy - like copay amount.
 * This takes a given task id and those fields and pulls the correct value out.
 * Fields that are task specific have the following data:
 *  {
 *    field_name_1: {
 *      taskId1: '$30',
 *      taskId2: '$40'
 *    }
 *  }
 */
const addTaskSpecificFields = (
  returnObj: any,
  fieldData: Partial<IFillCoordinationEditFormFields>,
  taskId: number,
) => {
  let onHandQty = fieldData.on_hand_qty?.[taskId];
  if (onHandQty == null) {
    onHandQty = undefined;
  }

  const dispenseQty = fieldData.dispense_qty?.[taskId] ?? undefined;

  returnObj.copay_amount = fieldData.copay_amount?.[taskId];
  returnObj.days_supply = fieldData.days_supply?.[taskId];
  returnObj.followup_dt = fieldData.followup_dt
    ? convertToArborDate(fieldData.followup_dt).getUtcDatetime()
    : undefined;
  returnObj.needsby_date = fieldData.needsby_date?.[taskId]
    ? convertToArborDate(fieldData.needsby_date[taskId]).getUtcDate()
    : undefined;
  returnObj.on_hand_qty = onHandQty;
  returnObj.patient_missed_doses = fieldData.patient_missed_doses;
  returnObj.next_needsby_date = fieldData.next_needsby_date?.[taskId]
    ? convertToArborDate(fieldData.next_needsby_date[taskId]).getUtcDate()
    : undefined;
  returnObj.needsby_date = fieldData.needsby_date?.[taskId]
    ? convertToArborDate(fieldData.needsby_date[taskId], true).getUtcDate()
    : undefined;
  returnObj.dispense_qty = dispenseQty;
  returnObj.service_group_id = fieldData.service_group_id?.[taskId];
  returnObj.authorization_code = fieldData.authorization_code?.[taskId];
  returnObj.rx_number = fieldData.rx_number?.[taskId];
  returnObj.drop_ship_med = fieldData.drop_ship_med?.[taskId];
};

export const getSiblingTaskTherapies = (
  task: IFillCoordinationTask,
  tasks: Record<TaskId, IFillCoordinationTask>,
  therapies: ITherapy[],
): { task: any; therapy: any }[] => {
  const currentOrderId = task?.order_id;
  const currentTaskId = task?.id;
  const taskObjs = Object.values(tasks);
  if (currentOrderId) {
    const siblingTasks = taskObjs.filter(
      x => x.taskType === FC && x.id !== currentTaskId && x.order_id === currentOrderId,
    );
    if (siblingTasks && siblingTasks.length) {
      const taskTherapy = siblingTasks.map(task => {
        const matchingTherapy = therapies[task.therapy_id];
        return {
          task: task,
          therapy: matchingTherapy,
        };
      });

      return taskTherapy;
    }
  }
  return [];
};

export const getChangedField = (prevProps: any, formValues: any, taskId: number) => {
  if (prevProps.formValues.on_hand_qty[taskId] !== formValues.on_hand_qty[taskId]) {
    return 'on_hand_qty';
  }
  if (prevProps.formValues.days_supply[taskId] !== formValues.days_supply[taskId]) {
    return 'days_supply';
  }
  if (prevProps.formValues.needsby_date[taskId] !== formValues.needsby_date[taskId]) {
    return 'needsby_date';
  }
  if (prevProps.formValues.dispense_qty[taskId] !== formValues.dispense_qty[taskId]) {
    return 'dispense_qty';
  }
  if (prevProps.formValues.service_group_id[taskId] !== formValues.service_group_id[taskId]) {
    return 'service_group_id';
  }
  return false;
};

export const getDiffDate = (date: moment.Moment) => {
  return date.diff(moment.utc().startOf('day'), 'days');
};

export const calculateValueOnInputChange = (
  input: keyof IFillCoordinationEditFormFields,
  changeFn: (field: string, value: any) => void,
  taskId: number,
  formValues: Partial<IFillCoordinationEditFormFields>,
):
  | { onHandQty?: number; needsByDate?: moment.Moment; nextNeedsByDate?: moment.Moment }
  | undefined => {
  switch (input) {
    /**
     * If on_hand_qty changes, set the needs by date = today + on_hand_qty.
     * Set the next_needsby_date = today + on_hand_qty + days_supply
     */
    case 'on_hand_qty': {
      const { nextNeedsByDate, onHandQty, needsByDate, daysSupply } = getTaskSpecificValues(
        taskId,
        formValues,
      );

      const newNeedsByDate = moment.utc().add(onHandQty <= 0 ? 0 : onHandQty, 'days');
      if (!needsByDate.isSame(newNeedsByDate, 'day')) {
        changeFn(
          nameOfFormFieldsPerTask('needsby_date', { id: taskId } as IFillCoordinationTask),
          newNeedsByDate,
        );
      }

      const newNextNeedsByDate = moment.utc().add(daysSupply + onHandQty, 'days');
      if (!nextNeedsByDate.isSame(newNextNeedsByDate, 'day')) {
        changeFn(
          nameOfFormFieldsPerTask('next_needsby_date', { id: taskId } as IFillCoordinationTask),
          newNextNeedsByDate,
        );
      }

      break;
    }
    /**
     * If days supply changes, set the next needs by date = needs by date + on hand qty
     */
    case 'days_supply': {
      const { nextNeedsByDate, daysSupply, onHandQty, needsByDate } = getTaskSpecificValues(
        taskId,
        formValues,
      );

      const newNextNeedsByDate = moment.utc().add(daysSupply + onHandQty, 'days');
      if (!newNextNeedsByDate.isSame(nextNeedsByDate, 'day')) {
        changeFn(
          nameOfFormFieldsPerTask('next_needsby_date', { id: taskId } as IFillCoordinationTask),
          newNextNeedsByDate,
        );
      }
      break;
    }

    /**
     * If needs by date changes set:
     *  on hand qty = new date - today's date
     *  next needs by date = on hand qty + days supply
     */
    case 'needsby_date': {
      const { nextNeedsByDate, daysSupply, onHandQty, needsByDate } = getTaskSpecificValues(
        taskId,
        formValues,
      );

      const newOnHandQty = (!needsByDate.isUTC() ? needsByDate.utc() : needsByDate).diff(
        moment.utc().startOf('day'),
        'days',
      );
      if (Math.abs(onHandQty) !== newOnHandQty) {
        changeFn(
          nameOfFormFieldsPerTask('on_hand_qty', { id: taskId } as IFillCoordinationTask),
          newOnHandQty <= 0 ? 0 : newOnHandQty,
        );
      }

      const newNextNeedsByDate = moment.utc().add(daysSupply + onHandQty, 'days');
      if (!nextNeedsByDate.isSame(newNextNeedsByDate, 'days')) {
        changeFn(
          nameOfFormFieldsPerTask('next_needsby_date', { id: taskId } as IFillCoordinationTask),
          newNextNeedsByDate,
        );
      }

      break;
    }
    default:
      break;
  }
  return undefined;
};

export const calculateNextNeedsByDate = (daysSupply: number, onHandQty: number): Date => {
  return moment
    .utc()
    .add(daysSupply + onHandQty, 'days')
    .startOf('day')
    .toDate();
};

export const calculateNeedsByDate = (onHandQty: number): Date => {
  const nonNegativeOnHandQty = Math.max(0, onHandQty ?? 0);
  return moment.utc().add(nonNegativeOnHandQty, 'days').startOf('day').toDate();
};

export const calculateOnHandQty = (needsByDate: Date): number => {
  const needsByMoment = moment.utc(needsByDate).startOf('day');
  const currentMoment = moment.utc().startOf('day');
  const daysDiffBetweenNeedsByAndCurrentMoments = needsByMoment.diff(currentMoment, 'days');
  return Math.max(0, daysDiffBetweenNeedsByAndCurrentMoments);
};

const getTaskSpecificValues = (
  taskId: number,
  currentValues: Partial<IFillCoordinationEditFormFields>,
): {
  daysSupply: number;
  needsByDate: moment.Moment;
  nextNeedsByDate: moment.Moment;
  onHandQty: number;
  dispenseQty: number;
  serviceGroupId: number;
  authorizationCode?: string;
} => {
  const daysNumber =
    typeof currentValues.days_supply?.[taskId] === 'number'
      ? currentValues.days_supply?.[taskId]
      : Number(currentValues.days_supply?.[taskId]);
  const daysSupply = daysNumber || 0;
  const needsByDate = currentValues.needsby_date?.[taskId] || moment();
  const nextNeedsByDate = currentValues.next_needsby_date?.[taskId] || moment();
  const authorizationCode = currentValues.authorization_code?.[taskId];
  const qtyNumber =
    typeof currentValues.on_hand_qty?.[taskId] === 'number'
      ? currentValues.on_hand_qty?.[taskId]
      : Number(currentValues.on_hand_qty?.[taskId]);
  const quantity = qtyNumber || 0;

  const dispenseQtyNumber =
    typeof currentValues.dispense_qty?.[taskId] === 'number'
      ? currentValues.dispense_qty?.[taskId]
      : Number(currentValues.dispense_qty?.[taskId]);
  const dispenseQuantity = dispenseQtyNumber || 0;

  const serviceGroupNumber =
    typeof currentValues.service_group_id?.[taskId] === 'number'
      ? currentValues.service_group_id?.[taskId]
      : Number(currentValues.service_group_id?.[taskId]);
  const serviceGroups = serviceGroupNumber || 0;

  return {
    daysSupply: daysSupply,
    needsByDate: needsByDate,
    nextNeedsByDate: nextNeedsByDate,
    onHandQty: quantity,
    dispenseQty: dispenseQuantity,
    serviceGroupId: serviceGroups,
    authorizationCode,
  };
};

const fulfillmentData = (therapiesId: number[], ancillarySupplies?: string): IFulfillment => {
  const splitAncillarySupplies = ancillarySupplies?.split(',');

  const arrAncillarySupplies = splitAncillarySupplies?.map(ancillarySupply => ({
    name: ancillarySupply,
    status: FulfillmentStatus.Complete,
  }));

  const arrTherapies = therapiesId?.map(id => ({ id: id, status: FulfillmentStatus.Complete }));

  return { ancillarySupplies: arrAncillarySupplies, therapies: arrTherapies };
};

const buildPayloadTask = (
  statusId: number,
  task: IFillCoordinationTask,
  formValues: Partial<IFillCoordinationEditFormFields>,
  patient: IPatient,
  contactList: IContactList,
): CompleteTaskPayload => {
  const allAddresses = getFullAddressList(patient, contactList);

  // The delivery and followup dates come from the form as UTC date-only fields, but they are actually datetime fields
  const customerTimezone = Timezone.getInstance().timezone;
  const deliveryDatetimeInCustomerTimezone = formValues.delivery_dt?.tz(customerTimezone, true);
  const followupDatetimeInCustomerTimezone = formValues.followup_dt?.tz(customerTimezone, true);

  const deliveryMethod =
    formValues.preferred_rx_delivery_method?.value === DeliveryMethodValue.Courier ||
    formValues.preferred_rx_delivery_method?.value === DeliveryMethodValue.PickUp;
  const shipMethod = formValues.shipping_method ? { [formValues.shipping_method]: true } : null;

  const payload: IBundleTask = {
    additional_medications: formValues.additional_medications,
    ancillary_supplies: formValues.ancillary_supplies,
    changes_in_medication: formValues.changes_in_medication,
    cold_chain_packing_required: formValues.cold_chain_packing_required,
    copay_amount: formValues.copay_amount?.[task.id],
    courier_type: formValues.courier_type,
    days_supply: formValues.days_supply?.[task.id],
    dispensing_pharmacy_npi: formValues.dispensing_pharmacy?.dispensing_pharmacy_npi,
    dispensing_pharmacy_name: formValues.dispensing_pharmacy?.dispensing_pharmacy_name,
    dispense_qty: formValues.dispense_qty?.[task.id],
    delivery_dt: deliveryDatetimeInCustomerTimezone?.toDate(),
    drop_ship_med: formValues.drop_ship_med?.[task.id],
    email: formValues.email?.value,
    followup_dt: followupDatetimeInCustomerTimezone?.toDate(),
    hospital_visit: formValues.hospital_visit,
    id: task.id,
    in_progress_payment_ids:
      statusId === FillCoordinationStatus.In_Progress ? formValues.payment_method_ids : undefined,
    in_progress_reason: formValues.in_progress_reason,
    is_notice_privacy_practices_sent: formValues.is_notice_privacy_practices_sent,
    medicaid_only: formValues.medicaid_only,
    method: formValues.method,
    needsby_date: formValues.needsby_date?.[task.id]?.utc().format(DATABASE_DATE_FORMAT),
    new_allergies: formValues.new_allergies,
    new_infections: formValues.new_infections,
    new_medical_conditions: formValues.new_medical_conditions,
    next_needsby_date: formValues.next_needsby_date?.[task.id]?.utc().format(DATABASE_DATE_FORMAT),
    on_hand_qty: formValues.on_hand_qty?.[task.id],
    order_notes: formValues.order_notes,
    order_type_address: formValues.order_type_address,
    patient_acknowledges: formValues.patient_acknowledges,
    patient_id: patient.id,
    patient_questions: formValues.patient_questions,
    payment_method_on_file: formValues.payment_method_on_file,
    patient_missed_doses: formValues.patient_missed_doses,
    preferred_phone_number: formValues.preferred_phone_number?.value,
    preferred_rx_delivery_method: formValues.preferred_rx_delivery_method?.value,
    preferred_shipping_address: allAddresses.find(
      (x: any) => x.label === formValues.address?.label,
    ),
    rx_number: formValues.rx_number?.[task.id],
    secure_link_id: task.secure_link_id || null,
    service_group_id: formValues.service_group_id?.[task.id],
    ship_date: formValues.ship_date?.utc().format(DATABASE_DATE_FORMAT),
    shipping_method: deliveryMethod ? null : shipMethod,
    side_effects: formValues.side_effects,
    signature_required: formValues.signature_required,
    special_instructions: formValues.special_instructions,
    spoke_to_patient_dt: formValues.spoke_to_patient_dt?.utc().format(DATABASE_DATETIME_FORMAT),
    status_id: statusId,
    taskType: FC,
    therapy_id: task.therapy_id,
    urgent: formValues.urgent,
    weather: formValues.weather,
    welcome_kit_sent: formValues.welcome_kit_sent,
    wk_pp_received: formValues.wk_pp_received,
    ups_packaging_type: formValues.ups_packaging_type?.value,
    to_md: formValues.to_md,
    to_patient: formValues.to_patient,
    authorization_code: formValues.authorization_code?.[task.id],
  };

  const isUsingFCSS =
    task.status_id === FILL_COORDINATION_STATUS.IN_PROGRESS_CALL_PATIENT ||
    task.status_id === FILL_COORDINATION_STATUS.IN_PROGRESS_PATIENT_RESPONSE_READY;

  const oldPayload: IBundleTaskOld = {
    old_additional_medications: task.additional_medications
      ? (JSON.parse(task.additional_medications) as IAdditionalMedication)
      : undefined,
    old_ancillary_supplies: task.ancillary_supplies
      ? (JSON.parse(task.ancillary_supplies) as Record<string, boolean>)
      : undefined,
    old_changes_in_medication: isUsingFCSS ? null : task.changes_in_medication,
    old_cold_chain_packing_required: task.cold_chain_packing_required,
    old_copay_amount: task.copay_amount,
    old_courier_type: task.courier_type,
    old_delivery_dt: task.delivery_dt,
    old_dispense_qty: task.dispense_qty,
    old_documents: task.documents,
    old_email: task.email,
    old_hospital_visit: isUsingFCSS ? null : task.hospital_visit,
    old_id: task.id,
    old_medicaid_only: task.medicaid_only,
    old_method: task.method,
    old_new_allergies: isUsingFCSS ? null : task.new_allergies,
    old_new_infections: isUsingFCSS ? null : task.new_infections,
    old_new_medical_conditions: task.new_medical_conditions,
    old_on_hand_qty: task.on_hand_qty,
    old_order_notes: task.order_notes,
    old_patient_acknowledges: task.patient_acknowledges,
    old_patient_missed_doses: formValues.patient_missed_doses,
    old_patient_questions: isUsingFCSS ? null : task.patient_questions,
    old_payment_method_on_file: task.payment_method_on_file,
    old_preferred_phone_number: task.preferred_phone_number,
    old_preferred_rx_delivery_method: task.preferred_rx_delivery_method,
    old_preferred_shipping_address: task.preferred_shipping_address,
    old_service_group_id: task.service_group_id,
    old_ship_date: task.ship_date,
    old_shipping_method: task.shipping_method
      ? (JSON.parse(task.shipping_method) as Record<string, boolean>)
      : undefined,
    old_side_effects: isUsingFCSS ? null : task.side_effects,
    old_signature_required: isUsingFCSS ? null : Boolean(task.signature_required),
    old_spoke_to_patient_dt: task.spoke_to_patient_dt,
    old_status_id: task.status_id,
    old_taskType: task.taskType,
    old_therapy_id: task.therapy_id,
    old_urgent: task.urgent,
    old_weather: task.weather ? (JSON.parse(task.weather) as IWeatherView) : undefined,
    old_ups_packaging_type: task.ups_packaging_type,
    old_to_md: task.to_md,
    old_to_patient: task.to_patient,
    old_rx_number: task.rx_number,
  };

  return {
    ...payload,
    ...oldPayload,
  };
};

const buildNotePayload = (
  task: IFillCoordinationTask,
  formValues: Partial<IFillCoordinationEditFormFields>,
  patient: IPatient,
): INote | undefined => {
  if (formValues.notes) {
    const note: INote = {
      patient_id: patient.id,
      note_type_id: NoteTypes.Patient,
      note_text: formValues.notes,
      is_pinned: false,
      mentions: [],
      tags: [
        {
          tag_type_id: NoteTagTypes.FC,
          resource_id: task.id,
        },
      ],
    };
    return note;
  }
  return undefined;
};

export const buildOrderPayload = (
  tasks: IFillCoordinationTask[],
  formValues: Partial<IFillCoordinationEditFormFields>,
): IOrder => {
  const arborDeliveryDt = convertToArborDate(formValues.delivery_dt);
  const arborShipDt = convertToArborDate(formValues.ship_date);
  const therapiesId = tasks.map(task => task.therapy_id);
  const fulfillment = fulfillmentData(therapiesId, formValues.ancillary_supplies);

  const isPickUpOrder = formValues.preferred_rx_delivery_method?.value === 1;

  return {
    address: isPickUpOrder ? undefined : formValues.address?.label,
    delivery_dt: arborDeliveryDt.getUtcDatetime() || undefined,
    /**
     * This method is the delivery method. 1 = pick up, 2 = delivery.
     * TODO: make all of the method / preferred delivery method / shipping method language
     * consistent between everything.
     */
    method:
      isDeliveryMethodSelected(formValues.preferred_rx_delivery_method?.value) ||
      isCourierMethodSelected(formValues.preferred_rx_delivery_method?.value)
        ? OrderMethod.Delivery
        : OrderMethod.PickUp,
    phone: formValues.preferred_phone_number?.value,
    rx_delivery_note: formValues.special_instructions,
    ship_dt: arborShipDt.getUtcDatetime() || undefined,
    signature_required: formValues.signature_required,
    taskIds: tasks.map(task => task.id),
    fulfillment: fulfillment,
    // Limit to only 4 maximum payment methods per order
    paymentMethodIds:
      (formValues.payment_method_ids || [])
        .filter(item => item > 0)
        .slice(0, MAX_SELECTED_PAYMENT_METHODS) ?? [],
  };
};

/**
 * Submits the bundle task payload to update the database
 * @param formValues Values from the FC form
 */
export const buildFullBundlePayload = (
  statusId: number,
  formValues: Partial<IFillCoordinationEditFormFields>,
  tasks: IFillCoordinationTask[],
  patient: IPatient,
  documents: IDocument[],
  newInterventions: IShortIntervention[],
  contactList: IContactList,
  removedFCIds?: number[],
) => {
  const payload: ICompleteFillCoordinationBundleTaskPayload = {
    tasks: [],
    orders: [],
    notes: [],
    documents: [],
    new_interventions: [],
  };
  const workingTasks = tasks.filter(task => !(removedFCIds || []).includes(task.id));

  try {
    workingTasks.forEach((task: IFillCoordinationTask) => {
      const taskPayload = buildPayloadTask(statusId, task, formValues, patient, contactList);
      payload.tasks.push(taskPayload);
      const notePayload = buildNotePayload(task, formValues, patient);
      if (notePayload) {
        payload.notes.push(notePayload);
      }
    });

    if (statusId === FillCoordinationStatus.Completed) {
      const order = buildOrderPayload(workingTasks, formValues);
      payload.orders.push(order);
    }

    payload.documents.push(...documents);

    payload.new_interventions = buildNewInterventionsPayload(newInterventions);

    return payload;
  } catch (error) {
    throw new Error('Could not build bundle task payload');
  }
};

export const buildCopayAmountListFromTasks = (
  tasks: ICompleteFcGeneratorTask[],
): (string | null)[] => tasks.map(task => task.copay_amount?.toString() ?? null);

/**
 * Calculate total copay amount for a map of taskId, copay amount value
 * @param copayAmounts List of copay amounts with dollar sign, can have null values (Ex: ['$33', '$23', null])
 * returns amount that can be a total calculated copay or null if all of them are null
 */
export const calculateTotalCopayAmount = (copayAmounts: (string | null)[]): string | null => {
  const isNotSpecified = copayAmounts.every(amount => amount === null || amount === '');
  if (isNotSpecified) {
    return null;
  }
  return copayAmounts
    .reduce(
      (accum, copayAmount) =>
        accum + Number(copayAmount ? stripFormattedDollarValue(copayAmount) : null),
      0,
    )
    ?.toFixed(2);
};

export const buildDetails = (reaction: string, causedByText: string): string => {
  if (reaction && causedByText) {
    return `During FC, patient declare to suffer from ${reaction}, suspected caused by ${causedByText}.`;
  }
  return '';
};

export const filterIntsBySideEffectType = (
  interventions: IShortIntervention[],
  sideEffectType: SideEffectType,
): { index: number; int: IShortIntervention }[] => {
  const newInterventions: { index: number; int: IShortIntervention }[] = [];
  interventions.forEach((int, index) => {
    if (int.sideeffect_type === sideEffectType) {
      newInterventions.push({
        index: index,
        int: int,
      });
    }
  });
  return newInterventions;
};

/**
 * Build a map of patient preferences->value to update based on selected ancillary supplies given
 * @param patient IPatient patient object with preferences to be evaluated
 * @param selectedAncillarySupplies string comma separated string representing the selected ancillary supplies
 * @returns map of preferences->value that needs to be updated
 */
export const buildPreferencesByAncillarySupplies = (
  patient: IPatient,
  selectedAncillarySupplies?: string,
): Partial<IPatient> => {
  let ancillarySupplyPreferences: Partial<IPatient> = {};

  ancillarySupplyPreferences = defaultPreferencesAncillarySupplies.reduce(
    (acc: Record<string, boolean>, ancillarySupplyKey) => {
      const isSelected = Boolean(
        selectedAncillarySupplies?.split(',').includes(ancillarySupplyKey),
      );
      // lookup if patient have a default value for current anc supply
      const defaultValue = Boolean((patient as any)[ancillarySupplyKey]);
      if (defaultValue !== isSelected) {
        acc[ancillarySupplyKey] = isSelected;
      }
      return acc;
    },
    {},
  );

  return ancillarySupplyPreferences;
};

export const getPaymentTypeLabel = (
  paymentMethod: PaymentMethodResponse,
  paymentMethodTypes: IPaymentMethodType[],
) =>
  paymentMethod
    ? paymentMethodTypes.find(({ value }) => value === paymentMethod.payment_type)?.label ?? ''
    : null;

export const getDeliveryMethodLogo = (deliveryMethod: DeliveryMethodLabel) => {
  switch (deliveryMethod) {
    case DeliveryMethodLabel.FedEx: {
      return FedexLogo;
    }
    case DeliveryMethodLabel.Ups: {
      return UpsLogo;
    }
    case DeliveryMethodLabel.Usps: {
      return UspsLogo;
    }
    default: {
      return undefined;
    }
  }
};

export const getUpsPackagingTypeLabel = (
  upsPackagingTypeOptions: IUpsPackagingTypes[],
  upsPackagingTypeId: number,
): ILabelValueNumber | undefined => {
  if (!upsPackagingTypeOptions || upsPackagingTypeOptions.length === 0 || !upsPackagingTypeId) {
    return undefined;
  }

  const upsPackagingTypeSelected = upsPackagingTypeOptions.find(
    option => option.id === upsPackagingTypeId,
  );

  if (!upsPackagingTypeSelected) {
    return undefined;
  }

  return {
    value: upsPackagingTypeSelected.id,
    label: upsPackagingTypeSelected.packaging_type,
  };
};

export const isAddressRequired = (
  deliveryMethodValue: number | undefined,
  selectedStatus: number,
): boolean => {
  return (
    (isDeliveryMethodSelected(deliveryMethodValue) ||
      isCourierMethodSelected(deliveryMethodValue)) &&
    selectedStatus === FillCoordinationStatus.Completed
  );
};

/**
 * used in UI for fc edit form
 * - show the message in status bar
 * - disable fc status complete button
 * - disable submit button
 */
export const isDurRequiredForFc = (tasks: ITask[]) => {
  const hasFcTask = tasks.some(task => task.taskType === 'FC');
  const durTaskIncomplete = tasks.some(
    task =>
      task.taskType === 'DUR' &&
      [TaskCategory.Outstanding, TaskCategory.InProgress].includes(
        task.status_category_id as TaskCategory,
      ),
  );
  return hasFcTask && durTaskIncomplete;
};

export const selectIsDurRequired = (state: IState) =>
  isDurRequiredForFc(Object.values(state.tasks?.data ?? {}));

export const getPreferredRxDeliveryAddress = (patient: IPatient, contactList: any) => {
  let referenceAddress = null;
  let entity = null;
  const preferredRxDeliveryEntityKey = patient.preferred_rx_delivery_entity_key;
  if (patient.preferred_rx_delivery_patient) {
    referenceAddress =
      patient.filteredAddresses && patient.filteredAddresses.length
        ? patient.filteredAddresses.find(item => item.key === preferredRxDeliveryEntityKey)
        : null;
    entity = patient;
  }

  if (patient.preferred_rx_delivery_contact_id) {
    referenceAddress = contactList[
      patient.preferred_rx_delivery_contact_id
    ]?.filteredAddresses?.find((item: any) => item.key === preferredRxDeliveryEntityKey);
    entity = contactList[patient.preferred_rx_delivery_contact_id];
  }

  if (referenceAddress) {
    return `${entity.first_name} ${entity.last_name} ${
      entity.relationship ? `(${entity.relationship})` : ''
    } - ${referenceAddress.use} - ${referenceAddress.line1}${
      referenceAddress.line2 ? ` ${referenceAddress.line2}` : ''
    }, ${referenceAddress.city}, ${referenceAddress.state}, ${referenceAddress.zip}`;
  }

  return 'Not configured';
};

const getFormNameArg = (_state: IState, formNameArg: string) => formNameArg;
const getFormState = (state: IState) => state.form;
export const getSelectFormState = createSelector(
  [getFormState, getFormNameArg],
  (formState, formName) => formState[formName] ?? {},
);
export const selectMissingRequiredKeys = createSelector(
  [getSelectFormState],
  ({ syncErrors = {} }) => Object.keys(syncErrors),
);
