import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Modal, Popover } from '@mui/material';
import { BaseTable } from 'containers/application-manager/base-table/base-table';
import { IHeaderCell } from 'containers/application-manager/base-table/types';
import History from 'containers/common/history';
import withStyles from '@mui/styles/withStyles';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { convertToArborDate } from 'models/time/arbor-date';
import { FieldTypes } from 'containers/application-manager/types';
import { IPopoverStateBase } from 'containers/application-manager/gtd/types';
import { FormField } from 'containers/application-manager/popover-field-forms/index';
import { PAGINATION_PARAMS } from 'containers/application-manager/ctd/constants';
import { getModalStyle } from 'services/utils/styles-service';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { fetchFinancialAssistance, editFinancialAssistanceTask } from 'actions/action-financials';
import { EditHistory, EditPencil } from 'components/icons/icons';
import { styles } from '../financials-styles';
import { dateConvert } from '../../../application-manager/utils';
import FinancialAssistanceTableDetail from '../tables-details/financial-assistance-table-detail';

interface IFinancialAssistancesTableProps {
  editFinancialAssistanceTask: any;
  fetchFinancialAssistance: any;
  dataset: IResult[];
  classes: Record<string, string>;
}

interface IResult {
  id: number;
  therapy_id: number;
  patient_id: number;
  member_id: string;
  copay: any;
  rewards_amount: any;
  financial_assistance_type: number;
  remaining_balance: any;
  status: number;
  award_amount: number;
  annual_limit: any;
  coverage_eligibilities: any;
  enrollment_url: any;
  income_details: any;
  expiration_date: string;
  household_size: any;
  household_income: any;
  foundation_program: string;
  spend_down: any;
  foundation_name: string;
  fax_number: any;
  program_name: any;
  check_notes: any;
  credit_card: any;
  bin: string;
  pcn: string;
  group_id: string;
  phone_number: string;
  instructions: any;
  free_drug_reason_id: any;
  zhi_program_id: any;
  created: string;
  created_by: number;
  updated: string;
  updated_by: string;
  financial_assistance_type_category: string;
  financial_assistance_type_sub_category: string;
  task_fa_id: number;
  order: number;
  children: undefined[];
}

export interface IFinancialAssistancesTableResponse {
  totalCount: number;
  results: IResult[];
}

type IPopoverState = IPopoverStateBase<IResult, IResult>;

const initialPopoverState: IPopoverState = {
  open: false,
};

const FinancialAssistancesTable: React.FC<IFinancialAssistancesTableProps> = (
  props: IFinancialAssistancesTableProps,
): JSX.Element => {
  const dispatch = useDispatch();
  const { classes } = props;

  // #region component state
  const [tableData, setTableData] = React.useState<IFinancialAssistancesTableResponse | undefined>(
    undefined,
  );
  const [lastReload, setLastReload] = React.useState<Date>(new Date());
  const [editItemMap, setEditItemMap] = React.useState<any>({});
  const [popoverState, setPopoverState] = React.useState<IPopoverState>({ open: false });
  const [editHistoryModalOpen, setEditHistoryModalOpen] = React.useState<boolean>(false);
  const [selectedItem, setSelectedItem] = React.useState<IResult>({} as IResult);
  const patientTherapies = useTypedSelector(state => state.therapies.data);
  // #endregion

  // #region helpers
  const handleEditClose = (financialAssistance: IResult) =>
    setEditItemMap({ ...editItemMap, [financialAssistance.id]: false });

  const openPopover = (
    element: HTMLElement,
    value: unknown,
    config: {
      parent?: IResult;
      child?: IResult;
      fieldType: FieldTypes;
      field: keyof IResult;
      forceAsParent?: boolean;
    },
  ): void => {
    if (config.parent != null && config.child != null) {
      throw new Error('Cannot select both a parent and child cell. Either one or the other');
    }

    setPopoverState({
      open: true,
      anchorElement: element,
      parent: config.parent,
      child: config.child,
      field: config.field,
      fieldType: config.fieldType,
      value: value,
      forceAsParent: config.forceAsParent,
    });
  };

  const getEditCellComponent = (parent: IResult) => {
    return (
      <div className={classes.editCellContainer}>
        <EditHistory
          onClick={(event: Event) => {
            event.stopPropagation();
            setSelectedItem(parent);
            setEditHistoryModalOpen(true);
          }}
        />
        <EditPencil
          onClick={(event: Event) => {
            setEditItemMap({ ...editItemMap, [parent.id]: true });
          }}
        />
      </div>
    );
  };

  const COLUMN_SETTINGS: IHeaderCell<IResult, undefined>[] = [
    {
      label: 'Therapy',
      parentKey: 'therapy_id',
      sortable: true,
      hideLeftPadding: true,
      childColSpan: 10,
      // eslint-disable-next-line react/no-unstable-nested-components
      childComponent: (parent: IResult) => {
        return (
          <FinancialAssistanceTableDetail
            financialAssistance={parent}
            editItemMap={editItemMap}
            handleEditClose={() => handleEditClose(parent)}
          />
        );
      },
      parentMaxWidth: '300px',
      parentDisplayInline: true,
      parentValueFormatter: (value: any) => {
        return patientTherapies[value]?.drug_name;
      },
    },
    {
      label: 'Type',
      parentKey: 'financial_assistance_type_category',
      hideLeftPadding: true,
      sortable: true,
      hideChildCol: true,
    },
    {
      label: 'Start Date',
      parentKey: 'created',
      hideLeftPadding: true,
      sortable: true,
      parentValueFormatter: dateConvert,
      hideChildCol: true,
    },
    {
      label: 'End Date',
      parentKey: 'expiration_date',
      hideLeftPadding: true,
      sortable: true,
      parentValueFormatter: dateConvert,
      hideChildCol: true,
    },
    {
      label: 'Status',
      parentKey: 'status',
      hideLeftPadding: true,
      hideChildCol: true,
      sortable: true,
      parentValueFormatter: (value: any, parent) => {
        return !value || convertToArborDate(parent.expiration_date, true).isBeforeToday()
          ? 'Inactive'
          : 'Active';
      },
      parentCellClick: (event, parent) => {
        openPopover(event.currentTarget, !!parent?.status, {
          parent,
          field: 'status',
          fieldType: FieldTypes.ActiveInactive,
        });
      },
    },
    {
      label: 'BIN',
      parentKey: 'bin',
      hideLeftPadding: true,
      sortable: true,
      hideChildCol: true,
    },
    {
      label: 'PCN',
      parentKey: 'pcn',
      hideLeftPadding: true,
      sortable: true,
      hideChildCol: true,
    },
    {
      label: 'Group',
      parentKey: 'group_id',
      hideLeftPadding: true,
      sortable: true,
      hideChildCol: true,
    },
    {
      label: 'Member ID',
      parentKey: 'member_id',
      hideLeftPadding: true,
      sortable: true,
      hideChildCol: true,
    },
    {
      label: '',
      sortable: false,
      hideLeftPadding: true,
      hideChildCol: true,
      parentComponent: getEditCellComponent,
    },
  ];

  const mapDataToTableResult = (data: IResult): any => {
    const result = {
      ...data,
      children: [data],
    } as any;
    return result;
  };

  useEffect(() => {
    const data = props.dataset;
    setTableData({ totalCount: data?.length, results: data?.map(mapDataToTableResult) });
    setLastReload(new Date());
  }, [props.dataset]);

  const getHistoryURL = (patientId: number, id: number) =>
    `/patients/${patientId}/financials/patient-financial-assistance/${id}`;

  const handlePopoverSubmit = (value: any) => {
    const {
      editFinancialAssistanceTask, //eslint-disable-line
      fetchFinancialAssistance, //eslint-disable-line
    } = props;
    const { field, parent: financialAssistance } = popoverState;
    const payload = {
      id: financialAssistance?.id,
      patient_id: financialAssistance?.patient_id,
      expiration_date: financialAssistance?.expiration_date
        ? convertToArborDate(financialAssistance.expiration_date).getUtcDate()
        : null,
      [field as string]: Number(value),
    } as any;

    editFinancialAssistanceTask(payload).then(() => {
      fetchFinancialAssistance(financialAssistance?.patient_id);
    });

    setPopoverState(initialPopoverState);
  };
  // #endregion

  // #region renders
  const renderPopover = (): JSX.Element => {
    const handleCancel = () => setPopoverState(initialPopoverState);

    return (
      <Popover
        id="form"
        open={popoverState.open}
        anchorEl={popoverState?.anchorElement}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transitionDuration={{ exit: 0 }}
        onClose={() => setPopoverState(initialPopoverState)}
      >
        <FormField
          initialValue={popoverState.value}
          handleCancel={handleCancel}
          handleSubmit={handlePopoverSubmit}
          fieldType={popoverState.fieldType}
        />
      </Popover>
    );
  };

  const renderHistoryModal = () => {
    return (
      <Modal open={editHistoryModalOpen} onClose={() => setEditHistoryModalOpen(false)}>
        <div style={getModalStyle()} className={classes.Modal}>
          <History url={getHistoryURL(selectedItem.patient_id, selectedItem.id)} />
        </div>
      </Modal>
    );
  };

  return (
    <>
      {renderPopover()}
      {renderHistoryModal()}
      <Grid container paddingX={4} className={classes.benefitTableContainer}>
        <Grid item xs={12}>
          <h2 className={classes.benefitTableTitle}>Financial Assistance</h2>
        </Grid>
        <Grid item xs={12}>
          <BaseTable<IFinancialAssistancesTableResponse, IResult, undefined>
            actionsPermitted
            triggerReload={lastReload}
            dataSet={tableData}
            orderByDefaultParent="therapy_id"
            paginationQueryParamSettings={PAGINATION_PARAMS}
            childPkSelector={() => ''}
            parentPkSelector={p => p.id}
            columnSettings={COLUMN_SETTINGS}
            searchTerm=""
            qaId="financial-assistance"
          />
        </Grid>
      </Grid>
    </>
  );
};

function mapStateToProps(state: any) {
  const { lookups } = state;

  return {
    users: lookups.users,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      editFinancialAssistanceTask,
      fetchFinancialAssistance,
    },
    dispatch,
  );
}

export default compose<IFinancialAssistancesTableProps, {}>(
  withStyles(styles as any),
  connect(mapStateToProps, mapDispatchToProps),
)(FinancialAssistancesTable);
